import { Subject } from 'rxjs';
import { Component, OnInit, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { navigation } from './navigation/navigation';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import * as moment from 'moment';

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Desk';
  fuseConfig: any;
  navigation: any;
  // Private
  private _unsubscribeAll: Subject<any>;
  constructor(
    @Inject(DOCUMENT) private document: any,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _translateService: TranslateService,
    private _platform: Platform
  ) {


    try {
      if (localStorage.getItem('currentUser')) {
        const _role = JSON.parse(localStorage.getItem('currentUser')!).role;
        // Get default navigation
        this.navigation = navigation.filter(item => (!item.roles || item.roles.includes(_role)));

        // Register the navigation to the service
        this._fuseNavigationService.register('main', this.navigation);
        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('main');
      }

    } catch (error) {
      console.log(error)
    }

    // Add languages
    this._translateService.addLangs(['en', 'tr']);

    // Set the default language
    this._translateService.setDefaultLang('tr');

    //console.log(localStorage.getItem('lang'));
    if (localStorage.getItem('lang')) {
      this._translateService.use(localStorage.getItem('lang')!);
    }
    else {
      // Use a language
      this._translateService.use('en');
      localStorage.setItem('lang', 'en');
    }

    moment.locale(localStorage.getItem('lang')!);


    // Add is-mobile class to the body if the platform is mobile
    if (this._platform.ANDROID || this._platform.IOS) {
      this.document.body.classList.add('is-mobile');
    }

    // Set the private defaults
    this._unsubscribeAll = new Subject();

  }

  ngOnInit(): void {
    // Subscribe to config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {

        this.fuseConfig = config;

        // Boxed
        if (this.fuseConfig.layout.width === 'boxed') {
          this.document.body.classList.add('boxed');
        }
        else {
          this.document.body.classList.remove('boxed');
        }

        // Color theme - Use normal for loop for IE11 compatibility
        for (let i = 0; i < this.document.body.classList.length; i++) {
          const className = this.document.body.classList[i];

          if (className.startsWith('theme-')) {
            this.document.body.classList.remove(className);
          }
        }

        this.document.body.classList.add(this.fuseConfig.colorTheme);
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }
  IsShowLogin(): boolean {
    return localStorage.getItem('currentUser') == null;
  }

  switchLanguage(language: string) {
    this._translateService.use(language);
    var currentUser = JSON.parse(localStorage.getItem('currentUser')!);
    if (currentUser == null)
      return;
    currentUser.language = language;
    localStorage.setItem('currentUser', JSON.stringify(currentUser));
  }

}
