<div class="fuse-countdown">

    <div class="time hours">
        <div class="value">
            {{countdown.hours}}
        </div>
        <div class="title">
            {{'G.HOURS' | translate}}
        </div>
    </div>

    <div class="time minutes">
        <div class="value">
            {{countdown.minutes}}
        </div>
        <div class="title">
            {{'G.MINUTES' | translate}}
        </div>
    </div>

    <div class="time seconds">
        <div class="value">
            {{countdown.seconds}}
        </div>
        <div class="title">
            {{'G.SECONDS' | translate}}
        </div>
    </div>

</div>