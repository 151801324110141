import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { FuseConfigService } from "@fuse/services/config.service";
import { AppAuthService } from "@services/auth.service";
import { CookieService } from "ngx-cookie-service";
import { navigation } from './navigation/navigation';
@Component({
    selector: 'mdm',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MdmAuth implements OnInit {

    navigation: any;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private cookieService: CookieService,
        private _route: Router,
        private _auth: AppAuthService
    ) {

    }
    ngOnInit() {
        localStorage.setItem('currentUser', this.cookieService.get('user'));
        this.cookieService.delete('user');

        setTimeout(() => {
            this._route.navigate(['/']);
        }, 10)
        
    }

}