import { FuseNavigation } from '@fuse/types';
export const navigation: FuseNavigation[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        translate: 'NAV.DASHBOARD',
        type: 'item',
        icon: 'dashboard',
        url: '/pages/dashboard'
    }, 
    {
        id: 'settings',
        title: 'Settings',
        translate: 'NAV.SETTINGS',
        type: 'collapsable',
        icon: 'settings',
        roles: ['admin','normal'],
        children: [
            {
                id: 'general',
                title: 'General',
                translate: 'NAV.GENERAL',
                type: 'item',
                url: '/pages/settings/general'
            },
            {
                id: 'users',
                title: 'Users',
                translate: 'NAV.USERS',
                type: 'item',
                url: '/pages/settings/users'
            }, 
            {
                id: 'logs',
                title: 'Logs',
                translate: 'NAV.LOGS',
                type: 'item',
                url: '/pages/settings/logs'
            }, 
            {
                id: 'changelog',
                title: 'Change Log',
                translate: 'NAV.CHANGELOG',
                type: 'item',
                url: '/pages/settings/changelog'
            }
        ]
    },
    {
        id: 'reports',
        title: 'Report',
        translate: 'NAV.REPORTS',
        type: 'collapsable',
        icon: 'receipt',
        roles: ['out'],
        children: [
            {
                id: 'report-general',
                title: 'General',
                translate: 'NAV.REPORTGENERAL',
                type: 'item',
                url: '/pages/report/general'
            },
            {
                id: 'report-user',
                title: 'User',
                translate: 'NAV.REPORTUSER',
                type: 'item',
                url: '/pages/report/user'
            } 
        ]
    },
    {
        id: 'logout',
        title: 'Logout',
        translate: 'NAV.LOGOUT',
        type: 'item',
        icon: 'exit_to_app',
        roles: ['admin', 'normal'],
        url: '/pages/auth/logout'
    }
];
