<div class="navbar-top" style="background-color: #289be5;">

    <div class="logo">
        <img class="" src="assets/images/logos/meetplat_logo_small_white.png">
    </div>

    <div class="buttons">

        <button mat-icon-button class="toggle-sidebar-folded"
                (click)="toggleSidebarFolded()" fxHide.lt-lg>
            <mat-icon class="secondary-text">menu</mat-icon>
        </button>

        <button mat-icon-button class="toggle-sidebar-opened"
                (click)="toggleSidebarOpened()" fxHide.gt-md>
            <mat-icon class="secondary-text">arrow_back</mat-icon>
        </button>

    </div>

</div>


<div class="navbar-scroll-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground"
     fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}">

    <div class="navbar-content">
        <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
    </div>

</div>

<div *ngIf="false" class="text-center" [ngClass]="fuseConfig.layout.navbar.primaryBackground" fxLayout="row" fxLayoutAlign="space-around center">
    <span><a href="https://www.bilnom.com/">bilnom</a> </span> <span><a href="/pages/changelog">{{ version }}</a> </span>
</div>