import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

const API_PRODUCTS_URL = '/api/login';
const API_LIC_URL = '/api/addLic';

@Injectable()
export class AppAuthService {
    public token: string;

    constructor(private http: HttpClient) {
        // set token if saved in local storage
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.token = currentUser && currentUser.token;
    }

    login(email, pass): Observable<any> {
        return this.http.post(API_PRODUCTS_URL, { email: email, pass: pass });
    }
    register(email, pass): Observable<any> {
        return this.http.post('/api/register', { email: email, pass: pass });
    }

    resetPass(id, pass): Observable<any> {
        return this.http.post('/api/resetPass', { id: id, pass: pass });
    }

    logout(): void {
        this.token = null;
        localStorage.removeItem('currentUser');
    }

    addLic(key): Observable<any> {
        return this.http.post(API_LIC_URL, { key: key });
    }

    checkRole(roles) {
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser) {
            return roles.includes(currentUser.role);
        }
        else {
            return false;
        }

    }

    deleteUser(id): Observable<any>{
        return this.http.post('/api/deleteUser', { id: id });
    }
    saveUser(record): Observable<any>{
        return this.http.post('/api/saveUser', { record: record });
    }
}
