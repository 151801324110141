<div *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md class="w-100-p" fxLayout="row" fxLayoutAlign="space-between start"
style="background-color: #0072bc; color: white">
    <button mat-icon-button class="navbar-toggle-button"
        (click)="toggleSidebarOpen('navbar')">
        <mat-icon class="secondary-text">menu</mat-icon>
    </button>
    <img class="h-64" src="assets/images/logos/meetplat_logo_small.png">
    <div *ngIf="selectedLanguage">
        <button mat-button class="language-button" [matMenuTriggerFor]="languageMenu">
            <div fxLayout="row" fxLayoutAlign="center center">
                <img class="flag mr-8" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.png'">
                <span class="iso text-uppercase">{{selectedLanguage.id}}</span>
            </div>
        </button>
        <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">
            <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                <span fxLayout="row" fxLayoutAlign="start center">
                    <img class="flag mr-16" [src]="'assets/icons/flags/'+lang.flag+'.png'">
                    <span class="iso">{{lang.title}}</span>
                </span>
            </button>
        </mat-menu>
    </div>
</div>